import { BaseIconPathStyle, BaseSvgStyle } from '../IconBaseStyle';
import { ComponentProps } from '../SVGBase';

function Card({ size, color }: ComponentProps) {
  return (
    <BaseSvgStyle
      size={size}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <BaseIconPathStyle
        d="M21.75 3.75H2.25C1.42157 3.75 0.75 4.42157 0.75 5.25V18.75C0.75 19.5784 1.42157 20.25 2.25 20.25H21.75C22.5784 20.25 23.25 19.5784 23.25 18.75V5.25C23.25 4.42157 22.5784 3.75 21.75 3.75Z"
        stroke={color}
        strokeRound
      />
      <BaseIconPathStyle d="M0.75 8.25H23.25" stroke={color} strokeRound />
      <BaseIconPathStyle d="M5.25 12.75H13.5" stroke={color} strokeRound />
      <BaseIconPathStyle d="M5.25 15.75H10.5" stroke={color} strokeRound />
    </BaseSvgStyle>
  );
}

export default Card;
